<template>
  <div>
    <territoryInfo></territoryInfo>
  </div>
</template>

<script>
import territoryInfo from "../../../components/territoryInfo"
export default {
 components:{
   territoryInfo
 }
}
</script>

<style>

</style>